import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25-editable/s25.editable.abstract";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25OptimizerEffectiveDatingUtil } from "../s25-optimizer-effective-dating/s25.optimizer.effective.dating.util";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { S25LoadingApi } from "../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-effective-dating-change-value")
@Component({
    selector: "s25-ng-effective-dating-change-value",
    template: `
        @if (init) {
            <div>
                <div>
                    @switch (changeType) {
                        @case (3) {
                            <div>
                                <input
                                    type="number"
                                    [(ngModel)]="roomChange.change_value"
                                    class="c-input"
                                    min="0"
                                    max="100"
                                    (change)="changeHandler($event)"
                                    aria-label="change value"
                                />
                            </div>
                        }
                        @case (5) {
                            <div>
                                <input
                                    type="number"
                                    [(ngModel)]="roomChange.change_value"
                                    class="c-input"
                                    min="0"
                                    max="99999999"
                                    (change)="changeHandler($event)"
                                    aria-label="change value"
                                />
                            </div>
                        }
                        @case (1) {
                            <div>
                                <s25-ng-multiselect-search-criteria
                                    [selectedItems]="selectedItems"
                                    [modelBean]="this.modelBean"
                                    honorMatching="false"
                                    [type]="'locationFeatures'"
                                    [customFilterValue]="'&include=s25'"
                                    [popoverOnBody]="true"
                                    (change)="onChangeValue($event)"
                                ></s25-ng-multiselect-search-criteria>
                            </div>
                        }
                        @case (2) {
                            <div>
                                <s25-ng-multiselect-search-criteria
                                    [selectedItems]="selectedItems"
                                    [modelBean]="this.modelBean"
                                    honorMatching="false"
                                    [type]="'locationFeatures'"
                                    [customFilterValue]="'&include=s25'"
                                    [popoverOnBody]="true"
                                    (change)="onChangeValue($event)"
                                ></s25-ng-multiselect-search-criteria>
                            </div>
                        }
                        @case (4) {
                            <div>
                                <s25-ng-multiselect-search-criteria
                                    [selectedItems]="selectedItems"
                                    [modelBean]="this.modelBean"
                                    honorMatching="false"
                                    [type]="'locationPartitions'"
                                    [popoverOnBody]="true"
                                    (change)="onChangeValue($event)"
                                ></s25-ng-multiselect-search-criteria>
                            </div>
                        }
                    }
                </div>
                <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
            </div>
        }
    `,
    styles: `
        .ngModalChangeValueContainer {
            width: 100%;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 40vw;
            max-height: 80vh;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EffectiveDatingChangeValueComponent extends S25EditableAbstract implements OnInit {
    @Input() changeType?: number = 1;
    @Output() onChange = new EventEmitter();
    @Input() roomChange?: any;

    init: boolean = false;
    changeTypes: any;
    modelBean: any = { showResult: true, showMatching: false, buttonText: "Edit", singleSelect: true };
    selectedItems: any = [];
    changeHandler: (changeContext: any) => void;

    min = 0;
    max = 100;
    preChange: any;
    contextType: SearchCriteriaType["type"] = "locationFeatures";
    include: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    getType = () => "location";

    async ngOnInit() {
        super.ngOnInit();

        this.zone.run(() => {
            this.preChange = {
                spaceId: S25Util.deepCopy(this.roomChange.room_id),
                effectiveDate: S25Util.deepCopy(this.roomChange.effective_date),
                changeType: S25Util.deepCopy(this.roomChange.change_type),
                changeValue: S25Util.deepCopy(this.roomChange.change_value),
            };
            this.changeType = this.roomChange.change_type;
            this.roomChange.changeValueText
                ? this.selectedItems.push({
                      itemId: this.roomChange.change_value,
                      itemName: this.roomChange.changeValueText,
                  })
                : "";

            // Debounce for keyboard users who have use arrow keys and cause a lot of events
            this.changeHandler = S25Util.debounce((context: any) => this.onChangeValue(context), 250);

            this.modelBean.onDone = () => {
                this.onChangeValue();
            };
        });

        this.init = true;
        this.cd.detectChanges();
    }

    onChangeValue(e?: any) {
        this.roomChange.preChange = this.preChange;

        if (
            this.changeType === 3 &&
            (this.roomChange.change_value > this.max ||
                this.roomChange.change_value < this.min ||
                !Number.isInteger(this.roomChange.change_value))
        ) {
            return alert("Please enter a valid integer between " + this.min + " and " + this.max + ".");
        } else if (
            this.changeType === 5 &&
            (this.roomChange.change_value < 0 ||
                !Number.isInteger(this.roomChange.change_value) ||
                this.roomChange.change_value > 99999999)
        ) {
            return alert("Please enter a valid integer number no more than 99999999.");
        }

        if (
            this.modelBean.addedItems &&
            this.modelBean.addedItems.length > 0 &&
            this.changeType !== 3 &&
            this.changeType !== 5
        ) {
            this.roomChange.change_value = this.modelBean.addedItems[0].itemId;
        }

        if (
            this.preChange.spaceId === this.roomChange.room_id &&
            this.preChange.effectiveDate === this.roomChange.effective_date &&
            this.preChange.changeType === this.roomChange.change_type &&
            this.preChange.changeValue === this.roomChange.change_value
        )
            return; // nothing changed not need to run update

        S25LoadingApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();

        return S25Util.all([S25OptimizerEffectiveDatingUtil.checkChangeValue(this.roomChange)]).then((resp) => {
            if (resp[0]) {
                this.onChange.emit(this.roomChange);
            } else {
                alert("Can't add, this location has same effective date and same change type in the system already.");
            }
        });
    }
}

/* 1: "Add Feature", 2: "Remove Feature", 3: "Update Fill Ratio", 4: "Update Partition",  5: "Update Capacity", */

// SearchCriteriaDataUtil.searchCriteriaSetup in JS, don't think need it in TS.
/*
     if (this.changeType === 1 || this.changeType === 3 || this.changeType === 4) {
          if (this.changeType === 4) {
               this.contextType = "locationPartitions";
          } else {
               this.contextType = "locationFeatures";
               this.include = "&include=s25";
          }
          SearchCriteriaDataUtil.searchCriteriaSetup(
               this.modelBean,
               SearchCriteriaContext.Context[this.contextType],
               this.selectedItems,
               this.elem.nativeElement,
               null,
               this.include
          ).then(() => {
               super.ngOnInit();
          });
     } else {
          super.ngOnInit();
     }
*/
