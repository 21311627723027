import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25OptimizerEffectiveDatingUtil } from "../s25-optimizer-effective-dating/s25.optimizer.effective.dating.util";
import { S25LoadingApi } from "../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-effective-dating-change-date")
@Component({
    selector: "s25-ng-effective-dating-change-date",
    template: `
        @if (init) {
            <div>
                <s25-ng-editable-date
                    [(val)]="this.roomChange.effective_date"
                    (valChange)="onChange($event)"
                    [alwaysEditing]="true"
                ></s25-ng-editable-date>
                <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EffectiveDatingChangeDateComponent {
    @Input() roomChange?: any;
    @Output() valChange = new EventEmitter();

    init: boolean = false;

    preChange: any;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.preChange = {
            spaceId: S25Util.deepCopy(this.roomChange.room_id),
            effectiveDate: S25Util.deepCopy(this.roomChange.effective_date),
            changeType: S25Util.deepCopy(this.roomChange.change_type),
            changeValue: S25Util.deepCopy(this.roomChange.change_value),
        };

        this.init = true;
        this.cd.detectChanges();
    }

    async onChange(e: any) {
        this.roomChange.effective_date = e;
        this.roomChange.preChange = this.preChange;

        if (
            this.preChange.spaceId === this.roomChange.room_id &&
            S25Util.date.equal(this.preChange.effectiveDate, this.roomChange.effective_date) &&
            this.preChange.changeType === this.roomChange.change_type &&
            this.preChange.changeValue === this.roomChange.change_value
        ) {
            this.init = false;
            setTimeout(() => {
                this.ngOnInit(); //reverse time format to user pref
            });
            return; // nothing changed not need to run update
        }

        S25LoadingApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();

        return S25Util.all([S25OptimizerEffectiveDatingUtil.checkChangeValue(this.roomChange)]).then((resp) => {
            if (resp[0]) {
                this.valChange.emit(this.roomChange);
            } else {
                alert("Can't add, this location has same effective date and same change type in the system already.");
            }
        });
    }
}
