//@author: devin
import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
    template: `
        <ng-template #defaultPopover>
            @if (bean) {
                <div>
                    {{ bean.message }}
                </div>
            }
            @if (!bean) {
                <div>TEST</div>
            }
        </ng-template>
    `,
})
export class PopoverDefaultComponent {
    @Input() bean: any;
    @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;
}
