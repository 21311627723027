//@author: devin
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PopoverComponent } from "./popover.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PopoverDefaultComponent } from "./popover.default.component";

@NgModule({
    declarations: [PopoverComponent, PopoverDefaultComponent],
    imports: [CommonModule, FormsModule, NgbModule],
    providers: [PopoverComponent, PopoverDefaultComponent],
    exports: [PopoverComponent],
})
export class PopoverModule {
    constructor() {}
}
