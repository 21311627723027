/*
       1: "Add Feature",
        2: "Remove Feature",
        3: "Update Fill Ratio",
        4: "Update Partition",
        5: "Update Capacity",
*/

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25OptimizerEffectiveDatingUtil } from "../s25-optimizer-effective-dating/s25.optimizer.effective.dating.util";
import { S25LoadingApi } from "../s25-loading/loading.api";

@TypeManagerDecorator("s25-ng-effective-dating-change-type")
@Component({
    selector: "s25-ng-effective-dating-change-type",
    template: `
        @if (init) {
            <div>
                <select
                    (change)="onChangeType($event)"
                    [(ngModel)]="this.changeType"
                    name="changeType"
                    class="ngListPageDropdown ng-pristine ng-valid ng-not-empty ng-touched cn-form__control"
                    aria-label="Select change type"
                    id="{{ roomChange.contextId }}"
                >
                    @for (t of changeTypes; track t; let i = $index) {
                        <option [value]="i + 1" [selected]="changeType == i + 1">
                            {{ t }}
                        </option>
                    }
                </select>
                <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EffectiveDatingChangeTypeComponent {
    @Input() roomChange?: any;
    @Output() onChange = new EventEmitter();
    init: boolean = false;
    changeTypes: any;
    changeType: number = 1;
    preChange: any;
    updating: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.changeType = this.roomChange.change_type;

        this.preChange = {
            spaceId: S25Util.deepCopy(this.roomChange.room_id),
            effectiveDate: S25Util.deepCopy(this.roomChange.effective_date),
            changeType: S25Util.deepCopy(this.roomChange.change_type),
            changeValue: S25Util.deepCopy(this.roomChange.change_value),
        };

        this.changeTypes = Object.keys(S25Const.spaceChangeType2Text).map((key) => [
            S25Const.spaceChangeType2Text[key],
        ]);

        this.init = true;
        this.cd.detectChanges();
    }

    async onChangeType(e: any) {
        this.changeType = parseInt(e.target.value);
        this.roomChange.change_type = this.changeType;
        this.roomChange.preChange = this.preChange;
        this.roomChange.change_value = 0;

        if (
            this.preChange.spaceId === this.roomChange.room_id &&
            this.preChange.effectiveDate === this.roomChange.effective_date &&
            this.preChange.changeType === this.roomChange.change_type &&
            this.preChange.changeValue === this.roomChange.change_value
        )
            return; // nothing changed not need to run update

        S25LoadingApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();
        return S25Util.all([S25OptimizerEffectiveDatingUtil.checkChangeValue(this.roomChange)]).then((resp) => {
            if (resp[0]) {
                this.onChange.emit(this.roomChange);
            } else {
                alert("Can't add, this location has same effective date and same change type in the system already.");
            }
        });
    }
}
