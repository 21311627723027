import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-pill")
@Component({
    selector: "s25-ng-pill",
    template: `
        <div class="pill">
            <button class="delete" (click)="remove.emit()">
                <s25-ng-icon [type]="'close'" [label]="'Remove'"></s25-ng-icon>
            </button>
            <div class="name"><ng-content></ng-content></div>
        </div>
    `,
    styles: `
        .pill {
            display: flex;
            width: fit-content;
            font-size: 0.9em;
        }

        .name {
            padding: 0.25em 0.5em 0.25em 0.5em;
            border-color: #3273a0 !important;
            color: #3273a0 !important;
            border: 1px solid rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: flex-end;
            border-top-right-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            font-size: 1.1em;
            line-height: 1em;
        }

        .delete {
            border-top-left-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            color: #d62000 !important;
            background-color: #faefef;
            border: 1px solid #d62000;
            border-right: 0;
            line-height: 1em;
        }

        .delete s25-ng-icon {
            font-size: 0.75em;
            margin: auto 0;
        }

        .delete:hover {
            color: #fff !important;
            background-color: #d62000 !important;
        }

        button {
            cursor: pointer;
        }
    `,
})
export class S25PillComponent {
    @Input() name: string;

    @Output() remove = new EventEmitter<void>();
}
