import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25OptimizerEffectiveDatingListComponent } from "./s25.optimizer.effective.dating.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25EffectiveDatingChangeTypeComponent } from "./s25.effective.dating.change.type.component";
import { S25EffectiveDatingChangeValueComponent } from "./s25.effective.dating.change.value.component";
import { S25EffectiveDatingChangeDateComponent } from "./s25.effective.dating.change.date.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
// import { S25ModalModule } from "../s25-modal/s25.modal.module";
// import { ModalModule } from "../modal/modal.module";

@NgModule({
    declarations: [
        S25OptimizerEffectiveDatingListComponent,
        S25EffectiveDatingChangeTypeComponent,
        S25EffectiveDatingChangeValueComponent,
        S25EffectiveDatingChangeDateComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        S25TableModule,
        S25EditableModule,
        S25MultiselectModule,
        S25LoadingInlineModule,
        //    S25ModalModule,
        //    ModalModule,
    ],
    providers: [
        S25OptimizerEffectiveDatingListComponent,
        S25EffectiveDatingChangeTypeComponent,
        S25EffectiveDatingChangeValueComponent,
        S25EffectiveDatingChangeDateComponent,
    ],
    exports: [
        S25OptimizerEffectiveDatingListComponent,
        S25EffectiveDatingChangeTypeComponent,
        S25EffectiveDatingChangeValueComponent,
        S25EffectiveDatingChangeDateComponent,
    ],
})
export class S25OptimizerEffectiveDatingModule {
    constructor() {}
}
