import { NgModule } from "@angular/core";
import { S25PillComponent } from "./s25.pill.component";
import { S25IconModule } from "../s25-icon/s25.icon.module";

@NgModule({
    declarations: [S25PillComponent],
    exports: [S25PillComponent],
    imports: [S25IconModule],
    providers: [S25PillComponent],
})
export class S25PillModule {}
