import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { SpaceService } from "../../services/space.service";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { ModalService } from "../modal/modal.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25TableUtil } from "../s25-table/s25.table.util";
import { S25EffectiveDatingChangeTypeComponent } from "./s25.effective.dating.change.type.component";
import { S25EffectiveDatingChangeValueComponent } from "./s25.effective.dating.change.value.component";
import { S25EffectiveDatingChangeDateComponent } from "./s25.effective.dating.change.date.component";
import { Api } from "../../api/api";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25OptimizerEffectiveDatingUtil } from "../s25-optimizer-effective-dating/s25.optimizer.effective.dating.util";
import { CacheRepository } from "../../decorators/cache.decorator";

export class EffectiveDatingApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(
            relativeElem,
            "s25-ng-optimizer-effective-dating-list",
            null,
            null,
            (comp: S25OptimizerEffectiveDatingListComponent) => {
                comp.refresh();
            },
        );
    }
}
@TypeManagerDecorator("s25-ng-optimizer-effective-dating-list")
@Component({
    selector: "s25-ng-optimizer-effective-dating-list",
    template: `
        <h2>Optimizer Effective Dating</h2>
        <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>

        @if (isInit) {
            <div>
                @if (action === "") {
                    <div>
                        <div>
                            <button
                                class="aw-button aw-button--primary c-margin-right--single c-margin-bottom--half"
                                (click)="onCreateClick()"
                            >
                                Add
                            </button>
                            <button
                                class="aw-button aw-button--primary c-margin-right--single c-margin-bottom--half"
                                (click)="remove()"
                                [disabled]="this.selectedItems.length === 0"
                            >
                                Remove
                            </button>
                            <button
                                class="aw-button aw-button--primary c-margin-right--single c-margin-bottom--half"
                                (click)="onEditClick()"
                                [disabled]="this.selectedItems.length === 0"
                            >
                                Edit
                            </button>
                        </div>
                        <s25-ng-table
                            [dataSource]="tableConfig"
                            [hasRefresh]="true"
                            [hasFilter]="false"
                            [columnSortable]="true"
                            [hasSelect]="true"
                            [hasSelectAll]="true"
                            [pivotThreshold]="0"
                            [unlimitedWidth]="true"
                            (selectedChange)="selectedChange($event)"
                        ></s25-ng-table>
                    </div>
                }
            </div>
        }
    `,
    styles: `
        ::ng-deep .s25-multiselect-popup-container {
            max-width: 40vw;
            max-height: 80vh;
        }

        ::ng-deep .s25-multiselect-popup .s25-multiselect-columns-container {
            max-width: 50vw;
            max-height: 35vh;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptimizerEffectiveDatingListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    isInit = false;
    tableConfig: Table.DataSource;
    spacesChanges: [];
    selectedItems: any = [];
    action: string = "";
    chosenItemIds: any = [];
    chosenModels: any = [];
    loading = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async ngOnInit() {
        this.loading = true;
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.cd.detectChanges();
        await this.getData();
        await this.initTableConfig();
        this.isInit = true;
        this.loading = false;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    initTableConfig() {
        this.tableConfig = S25TableUtil.emulateInfiniteScroll({
            columns: [
                {
                    id: "itemName",
                    header: "Location Name",
                    width: 200,
                    sortable: true,
                },
                {
                    id: "effective_date",
                    header: "Effective Date",
                    width: 180,
                    sortable: true,
                },
                {
                    id: "change_type",
                    header: "Change Type",
                    width: 150,
                    sortable: true,
                },
                {
                    id: "change_value",
                    header: "Change",
                    width: 180,
                    sortable: true,
                },
            ],
            rows: this.spacesChanges,
        });
    }

    @Bind
    async getData() {
        CacheRepository.invalidateByService("SpacesService");
        const spacesChanges = await SpaceService.getSpaceChangesWithNames();
        for (let rm of spacesChanges) {
            rm.contextId = rm.room_id + " " + rm.change_type + " " + rm.change_value + " " + rm.effective_date; //required for list-check to register which item was checked
        }
        this.spacesChanges = spacesChanges.map(this.mapToRow);
    }

    mapToRow(rm: any): Table.Row {
        return {
            id: rm.contextId,
            name: rm.itemName,
            cells: {
                itemName: { text: rm.itemName },
                effective_date: {
                    textValue: rm.effective_date,
                    component: S25EffectiveDatingChangeDateComponent,
                    inputs: { roomChange: rm },
                    outputs: {
                        valChange: (val: any, row: Table.Row) => {
                            S25OptimizerEffectiveDatingUtil.updateChangeValue(val);
                        },
                    },
                },
                change_type: {
                    textValue: rm.change_type,
                    component: S25EffectiveDatingChangeTypeComponent,
                    inputs: { roomChange: rm },
                    outputs: {
                        onChange: (val: any, row: Table.Row) => {
                            S25OptimizerEffectiveDatingUtil.updateChangeValue(val);
                        },
                    },
                },
                change_value: {
                    textValue: rm.change_value,
                    component: S25EffectiveDatingChangeValueComponent,
                    inputs: { changeType: parseInt(rm.change_type), roomChange: rm },
                    outputs: {
                        onChange: (val: any, row: Table.Row) => {
                            S25OptimizerEffectiveDatingUtil.updateChangeValue(val);
                        },
                    },
                },
            }, //end cells
        };
    }

    @Bind
    async refresh() {
        this.loading = true;
        await this.getData();
        await this.initTableConfig();
        this.isInit = false;
        this.selectedItems = [];
        this.cd.detectChanges();
        //this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        //await S25Util.delay(250);
        setTimeout(() => {
            this.isInit = true;
            this.loading = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.cd.detectChanges();
            // this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        }, 60);
    }

    selectedChange(selectItem: any) {
        this.selectedItems = Array.from(selectItem);
        let ret: any = [];
        let count = 0;
        const len = this.selectedItems.length;
        const models = new Array(len);
        for (var i = 0; i < len; i++) {
            const selectedItemVals = this.selectedItems[i].split(" ");
            models[i] = {
                spaceId: selectedItemVals[0],
                changeType: selectedItemVals[1],
                changeValue: selectedItemVals[2],
                effectiveDate: S25Util.date.parse(selectedItemVals[3]),
            };
            this.chosenItemIds.push(parseInt(selectedItemVals[0]));
        }

        this.chosenItemIds = S25Util.array.unique(this.chosenItemIds);
        this.chosenModels = models;
        this.cd.detectChanges();
    }

    remove() {
        if (this.chosenModels.length > 0) {
            SpaceService.deleteSpaceChangeModels(this.chosenModels).then(function (resp) {
                if (
                    resp &&
                    resp.error &&
                    resp.error.results &&
                    resp.error.results.error &&
                    resp.error.results.error.proc_error
                ) {
                    alert(resp.error.results.error.msg + ". " + resp.error.results.error.proc_error);
                } else {
                    let el: any = document;
                    EffectiveDatingApi.refresh(el);
                }
            });
        }

        this.selectedItems = [];
        this.tableComponent.refresh(true);
    }

    onCreateClick() {
        ModalService.modal("optimizer-effective-dating-ng", {
            itemIds: "",
            action: "add",
            title: `Add Optimizer Effective Dating`,
            onSave: this.refresh,
        });
    }

    onEditClick() {
        ModalService.modal("optimizer-effective-dating-ng", {
            itemIds: this.chosenItemIds,
            action: "edit",
            chosenModels: this.chosenModels,
            title: `Edit Optimizer Effective Dating`,
            onSave: this.refresh,
        });
    }
}
