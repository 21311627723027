//@author: Mandy
import { S25Util } from "../../util/s25-util";
import { SpaceService } from "../../services/space.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { EffectiveDatingApi } from "./s25.optimizer.effective.dating.list.component";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { ModalData } from "../modal/modal.service";
import { ModalHeaderI } from "../modal/modal.header.component";
import { S25WsNode } from "../../pojo/S25WsNode";
import { S25ItemI } from "../../pojo/S25ItemI";
import { Proto } from "../../pojo/Proto";
import NumericalString = Proto.NumericalString;
declare global {
    interface Window {
        angBridge: any;
    }
}
export class S25OptimizerEffectiveDatingUtil {
    public static async checkChangeValue(rm: any) {
        let allSpacesChanges = await SpaceService.getSpaceChangesWithNames();
        let noMatchFind: any = [];
        if (allSpacesChanges) {
            if (rm.change_type === 1 || rm.change_type === 2) {
                noMatchFind = allSpacesChanges.filter((a: any) => {
                    return (
                        a &&
                        a.change_type === rm.change_type &&
                        a.change_value === rm.change_value &&
                        a.effective_date === S25Datefilter.transform(rm.effective_date, "yyyy-MM-dd") &&
                        a.room_id === rm.room_id
                    );
                });
            } else {
                //if  3: "Update Fill Ratio",4: "Update Partition", 5: "Update Capacity", change date or type check any match
                if (rm.change_type !== rm.preChange.changeType || rm.effective_date !== rm.preChange.effectiveDate) {
                    noMatchFind = allSpacesChanges.filter((a: any) => {
                        return (
                            a &&
                            a.change_type === rm.change_type &&
                            a.effective_date === S25Datefilter.transform(rm.effective_date, "yyyy-MM-dd") &&
                            a.room_id === rm.room_id
                        );
                    });
                }
            }

            if (noMatchFind.length === 0) {
                return true;
            } else {
                let el: any = document;
                EffectiveDatingApi.refresh(el);
                return false;
            }
        } else {
            let el: any = document;
            EffectiveDatingApi.refresh(el);
            return true;
        }
    }

    public static updateChangeValue(val: any) {
        let model: any = {
            spaceId: val.room_id,
            effectiveDate: val.effective_date,
            changeType: val.change_type,
            changeValue: val.change_value,
        };

        return S25Util.all([
            SpaceService.deleteSpaceChangeModels(val.preChange),
            SpaceService.insertSpaceChangeModels(model),
        ]).then((resp) => {
            let el: any = document;
            EffectiveDatingApi.refresh(el);
        });
    }

    public static getAllSpaceChanges() {
        return SpaceService.getAllSpaceChanges().then((resp) => {
            return resp;
        });
    }

    public static async updateChanges(
        data: DataI,
        modelBeanLoc: MultiselectModelI,
        changeType: number,
        changeValue: number,
        modelBean: MultiselectModelI,
        effectiveDate: Date,
    ) {
        let min = 0;
        let max = 100;
        let getData = await S25OptimizerEffectiveDatingUtil.getAllSpaceChanges();

        if (data.action === "add" && (!modelBeanLoc.addedItems || modelBeanLoc.addedItems.length === 0)) {
            alert("Please select a location.");
            return false;
        } else {
            if (data.action === "add") {
                data.itemIds = modelBeanLoc.addedItems;
                data.itemIds = S25Util.toItemIds(data.itemIds);
            }

            if (changeType === 3 && (changeValue > max || changeValue < min || !Number.isInteger(changeValue))) {
                return alert("Please enter a valid integer between " + min + " and " + max + ".");
            } else if (
                changeType === 5 &&
                (changeValue < 0 || !Number.isInteger(changeValue) || changeValue > 99999999)
            ) {
                return alert("Please enter a valid integer number no more than 99999999.");
            } else if (changeType === 4 && !changeValue) {
                return alert("Please select a partition.");
            } else if (
                (changeType === 1 || changeType === 2) &&
                (!modelBean.addedItems || modelBean.addedItems.length === 0)
            ) {
                alert("Please select a feature.");
                return false;
            } else {
                let len = data.itemIds.length;
                let models: Array<any> = [];
                if (changeType === 3 || changeType === 4 || changeType === 5) {
                    if (data.action === "edit" && data.chosenModels.length > 0) {
                        SpaceService.deleteSpaceChangeModels(data.chosenModels).then((resp) => {
                            if (
                                resp &&
                                resp.error &&
                                resp.error.results &&
                                resp.error.results.error &&
                                resp.error.results.error.proc_error
                            ) {
                                return alert(resp.error.results.error.msg + ". " + resp.error.results.error.proc_error);
                            } else {
                                S25OptimizerEffectiveDatingUtil.getAllSpaceChanges(); // refresh getDate after update
                            }
                        });
                    }

                    models = new Array(len);

                    for (let i = 0; i < len; i++) {
                        // Prevent multiple rows for capacity, fill ratio, or partition for single location/effective date
                        let currentItem = {
                            spaceId: data.itemIds[i].toString(), // service data tyep is string
                            effectiveDate: S25Datefilter.transform(effectiveDate, "yyyy-MM-dd"),
                            changeType: changeType.toString(), //service data tyep is string
                            changeValue: changeValue,
                        };
                        let currentItemPush: chosenModel = {
                            spaceId: data.itemIds[i].toString(),
                            effectiveDate: effectiveDate,
                            changeType: changeType.toString(),
                            changeValue: changeValue.toString(),
                        };
                        let getMatch: Array<any> = [];
                        if (getData.length > 0) {
                            // if edit filter value, add no need to filter value
                            if (data.action === "edit") {
                                getMatch = getData.find(
                                    (i: OptimizerI) =>
                                        i.room_id === currentItem.spaceId &&
                                        i.effective_date === currentItem.effectiveDate &&
                                        i.change_type === currentItem.changeType &&
                                        parseInt(i.change_value) === currentItem.changeValue,
                                );
                            } else {
                                getMatch = getData.find(
                                    (i: OptimizerI) =>
                                        i.room_id === currentItem.spaceId &&
                                        i.effective_date === currentItem.effectiveDate &&
                                        i.change_type === currentItem.changeType,
                                );
                            }

                            if (getMatch) {
                                currentItemPush.remove = true;
                                models[i] = currentItemPush;
                            } else {
                                models[i] = currentItemPush;
                            }
                        } else {
                            models[i] = currentItemPush;
                        }
                    } //end for loop

                    // filter record already exit to Prevent multiple rows for capacity, fill ratio, or partition for single location/effective date ANG-3599
                    let newModels = models.filter((item: chosenModel) => !item.remove);
                    models = newModels;
                } else {
                    // features
                    let changeValueItems = modelBean.addedItems;
                    let changeValueItemsLen = modelBean.addedItems.length;
                    models = [];
                    for (let i = 0; i < len; i++) {
                        for (let j = 0; j < changeValueItemsLen; j++) {
                            models.push({
                                spaceId: data.itemIds[i],
                                effectiveDate: effectiveDate,
                                changeType: changeType,
                                changeValue: changeValueItems[j].itemId,
                            });
                        }
                    } // end for loop fn
                }
                return models;
            }
        }
    }
}
/*
     rm_changes.json? data return as string example:
     {
          "room_id" : "9",
          "change_type" : "5",
          "change_value" : "53",
          "effective_date" : "2019-01-16",
          "status" : "est"
     }
*/
export interface OptimizerI {
    room_id?: string;
    effective_date?: string;
    change_type?: string;
    change_value?: string;
    status?: S25WsNode["status"];
}

type chosenModel = {
    spaceId?: string;
    effectiveDate?: Date;
    changeType?: string;
    changeValue?: string;
    remove?: boolean;
};
export interface DataI extends ModalData, ModalHeaderI {
    action?: string;
    chosenModels?: chosenModel[];
    itemIds?: number[] | S25ItemI[];
}
